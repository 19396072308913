<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0" v-if="parameters.remitFromOrder">Pendientes - Remitos desde Pedidos</h4>
            <h4 id="traffic" class="card-title mb-0" v-if="parameters.remitFromSale">Pendientes - Remitos desde Ventas</h4>
            
            <div class="small text-muted">Listado con todos los productos por remitir</div>
          </b-col>                            
        </b-row>
      </b-card>

      <b-card>
        <b-row>
          <b-col sm="10">
            <b-row>     
              <b-col sm="3">
                <v-select :options="arr.filters.zone" v-model="filters.zone" placeholder="Zona" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>                                                        
              <b-col sm="3">
                <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              <b-col sm="3">
                <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
              </b-col> 
              <b-col sm="2">
                <b-button variant="outline-dark" @click="loadCustomers()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                  class = 'pull-right'
                  :data = "arr.export"
                  worksheet = "Listado de Pendiente a Remitir"
                  name = "list-pending-remits.xls">
                  Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="3">
          <b-card>
            <vue-tree-list
              @click="selectedItem"              
              :model="customersTree"
              v-bind:default-expanded="false"              
            >                      
              <template v-slot:leafNameDisplay="slotProps">                
                <span>
                  &nbsp;&nbsp;{{ slotProps.model.name }}
                </span>
              </template>              

              <span class="icon" slot="treeNodeIcon">📌&nbsp;</span>    
              <span class="icon" slot="leafNodeIcon">🏢&nbsp;</span>
            </vue-tree-list>                     

            <div v-if="customers">
              <b-alert show v-if="!customers.length" variant="warning">
                No se encontraron clientes pendientes por remitir
              </b-alert>
            </div>
          </b-card>          
        </b-col>        
        <b-col lg="9">
          <b-card>
            <b-row>
              <b-col sm="12">
                <b-overlay :show="overlay.remits" rounded="sm">
                  <b-table-simple hover small caption-top responsive v-if="arr.pending.length">             
                    <b-thead head-variant="dark">                                          
                      <b-tr v-if="parameters.remitMultipleItems">                                       
                        <b-th width="10%" class="text-left">Fecha</b-th>
                        <b-th width="20%" class="text-left">Comprobante</b-th>
                        <b-th width="7%" class="text-left">Código</b-th>
                        <b-th width="33%" class="text-left">Descripción</b-th>
                        <b-th width="6%" class="text-center">Estado Vto.</b-th>
                        <b-th width="8%" class="text-center">Cantidad</b-th>
                        <b-th width="8%" class="text-center">Remitido</b-th>
                        <b-th width="8%" class="text-center">Pendiente</b-th>                                            
                      </b-tr>
                      <b-tr v-else>
                        <b-th width="10%" class="text-left">Fecha</b-th>
                        <b-th width="20%" class="text-left">Comprobante</b-th>
                        <b-th width="70%" class="text-center">Productos</b-th>
                      </b-tr>
                    </b-thead>      
                    <b-tbody v-for="item in arr.pending" :key="item.id"> 
                      <b-tr v-if="parameters.remitMultipleItems" :variant="getVariant(item)">                                       
                        <b-th class="text-left align-middle">                        
                          <div v-if="parameters.remitFromSale">
                            {{moment(item.sale.date).format('DD/MM/YYYY')}}                        
                          </div>
                          <div v-if="parameters.remitFromOrder">
                            {{moment(item.order.date).format('DD/MM/YYYY')}}                        
                          </div>
                        </b-th>

                        <b-th class="text-left align-middle">
                          <div v-if="parameters.remitFromSale">
                            <div v-if="item.sale.type_voucher && item.sale.point_sale">                          
                              {{item.sale.type_voucher.name}}
                              {{item.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.sale.number.toString().padStart(8,'0')}}
                            </div>
                          </div>
                          <div v-if="parameters.remitFromOrder">
                            <div v-if="item.order.points_sales">
                              <b-badge variant="secondary">{{item.order.points_sales.point_sale}}</b-badge>
                              <br>  
                            </div>
                            PEDIDO Nº {{item.order.id}}                            
                          </div>                                                                        
                        </b-th>

                        <b-td class="text-left align-middle">{{item.selectProduct.code}}</b-td>

                        <b-td class="text-left align-middle">
                          <div v-if="parameters.remitFromSale">
                            {{item.description}}
                          </div>
                          <div v-if="parameters.remitFromOrder">
                            {{item.selectProduct.name}}
                          </div>
                        </b-td>

                        <b-td class="text-center align-middle">
                          <div v-if="parameters.remitFromSale && item.vto.days">
                            {{item.vto.status}}
                          </div>
                        </b-td>

                        <b-td class="text-center align-middle">
                          {{item.quantity}}
                          <span v-if="item.unit_measurement">{{item.unit_measurement.reference}}</span>
                        </b-td>                      
                        <b-td class="text-center align-middle">
                          {{item.quantity_remit}}
                          <span v-if="item.unit_measurement">{{item.unit_measurement.reference}}</span>
                        </b-td>                      
                        <b-td class="text-center align-middle">
                          {{parseFloat(item.quantity) - parseFloat(item.quantity_remit)}}
                          <span v-if="item.unit_measurement">{{item.unit_measurement.reference}}</span>
                        </b-td>                      
                      </b-tr>

                      <b-tr v-else>
                        <b-th class="text-left align-middle">                        
                          {{moment(item.date).format('DD/MM/YYYY')}}                        
                        </b-th>

                        <b-th class="text-left align-middle">
                          <div v-if="parameters.remitFromSale">
                            <div v-if="item.type_voucher && item.point_sale">                          
                              {{item.type_voucher.name}}
                              {{item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')}}
                            </div>
                          </div>
                          <div v-if="parameters.remitFromOrder">
                            <div v-if="item.points_sales">
                              <b-badge variant="secondary">{{item.points_sales.point_sale}}</b-badge>
                              <br>
                            </div>
                            PEDIDO Nº {{item.id}}                            
                          </div>                                                                        
                        </b-th>     

                        <b-td class="text-center align-middle">
                          <b-table-simple hover small caption-top responsive v-if="item.details.length">             
                            <b-thead head-variant="dark">                                          
                              <b-tr>              
                                <b-th width="5%" class="text-left">Código</b-th>
                                <b-th width="35%" class="text-left">Descripción</b-th>
                                <b-th width="10%" class="text-center">Cantidad</b-th>
                                <b-th width="10%" class="text-center">Remitido</b-th>
                                <b-th width="10%" class="text-center">Pendiente</b-th>                                                            
                              </b-tr>
                            </b-thead>
                            <b-tbody v-for="item1 in item.details" :key="item1.id"> 
                              <b-tr v-if="parameters.remitFromSale" :class="{'table-success':parseFloat(item1.quantity) == parseFloat(item1.quantity_remit), 'table-warning':parseFloat(item1.quantity) != parseFloat(item1.quantity_remit)}">                            
                                <b-td class="text-left align-middle">{{item1.selectProduct.code}}</b-td>
                                <b-td class="text-left align-middle">{{item1.description}}</b-td>
                                <b-td class="text-center align-middle">
                                  {{item1.quantity}}
                                  <span v-if="item1.unit_measurement">{{item1.unit_measurement.reference}}</span>
                                </b-td>                      
                                <b-td class="text-center align-middle">
                                  {{item1.quantity_remit}}
                                  <span v-if="item1.unit_measurement">{{item1.unit_measurement.reference}}</span>
                                </b-td>                      
                                <b-td class="text-center align-middle">
                                  {{parseFloat(item1.quantity) - parseFloat(item1.quantity_remit)}}
                                  <span v-if="item1.unit_measurement">{{item1.unit_measurement.reference}}</span>
                                </b-td>                                 
                              </b-tr>
                              <b-tr v-if="parameters.remitFromOrder" :class="{'table-success':parseFloat(item1.quantity) == parseFloat(item1.quantity_remit), 'table-warning':parseFloat(item1.quantity) != parseFloat(item1.quantity_remit)}">                            
                                <b-td class="text-left align-middle">{{item1.selectProduct.code}}</b-td>
                                <b-td class="text-left align-middle">{{item1.selectProduct.name}}</b-td>
                                <b-td class="text-center align-middle">
                                  {{item1.quantity}}
                                  <span v-if="item1.unit_measurement">{{item1.unit_measurement.reference}}</span>
                                </b-td>                      
                                <b-td class="text-center align-middle">
                                  {{item1.quantity_remit}}
                                  <span v-if="item1.unit_measurement">{{item1.unit_measurement.reference}}</span>
                                </b-td>                      
                                <b-td class="text-center align-middle">
                                  {{parseFloat(item1.quantity) - parseFloat(item1.quantity_remit)}}
                                  <span v-if="item1.unit_measurement">{{item1.unit_measurement.reference}}</span>
                                </b-td>                                 
                              </b-tr>                            
                            </b-tbody>
                          </b-table-simple>
                        </b-td>                                       
                      </b-tr>
                    </b-tbody>                              
                  </b-table-simple>   
                  <b-alert show variant="info" v-else>
                    No hay productos para remitir.
                  </b-alert>  
                </b-overlay>              
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="12">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>        
            </b-row>
          </b-card>
        </b-col>
      </b-row>     
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Funciones from '@/handler/funciones' 
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Param from '@/config/parameters'
  import moment from 'moment'
  
  import Vue from 'vue'
  import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
  Vue.use(VueTreeList)  

  export default {
    components: {
      VueTreeList,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.REMITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'pending',
          elements: {}
        },            
        parameters: {
          remitFromOrder: Helper.hasParametersAccess(Param.P26),
          remitFromSale: Helper.hasParametersAccess(Param.P27),
          remitMultipleItems: Helper.hasParametersAccess(Param.P28),
        },                    
        arr: {
          export: [],     
          pending: [],
          filters : {
            zone: [],
            customers: [],            
            points_sales: [],
          },                                
        },           
        filters: {
          zone: null,
          customers: null,
          points_sales: null,
        },    
        customers: null,
        customersTree: new Tree([]),                   
        overlay: {
          remits: false
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },     
    mounted() {         
      this.loadCustomers()      
      this.filterLoad()      
    },        
    methods: {
      // EXPORTAR
      prepareExport(data) {        
        this.arr.export = []
        data.forEach(element => {
            var item = null
            if(element.order) {
              item = element.order
            } 
            if(element.sale) {
              item = element.sale
            }      

            var customersID = 0
            var customersCode = ""
            var customersName = ""
            var voucherReference = ""
            var productID = 0
            var productCode = ""
            var productName = ""
            var productCompoundID = 0
            var productCompoundName = ""     
            var unitMeasurementID = 0
            var unitMeasurementName = ""              
            
            if(item.customer) {
              customersID = item.customer.id
              customersCode = item.customer.code
              customersName = item.customer.name            
            }
            if(item.type_voucher) {            
              voucherReference = item.type_voucher.name + ' ' + item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
            }    
            if(element.products_id) {
              productID = element.product.id
              productCode = element.product.code
              productName = element.product.name            
            }

            if(element.products_colors_id) {
              productID = element.products_color.id
              productCode = element.products_color.code
              productName = element.products_color.name + " (" + element.products_color.color.name + ")"
            }            

            if(element.products_waist_id) {
              productID = element.products_waist.id
              productCode = element.products_waist.code
              productName = element.products_waist.name + " (" + element.products_waist.waist.name + ")"
            }            

            if(element.products_color_waist_id) {
              productID = element.products_color_waist.id
              productCode = element.products_color_waist.code
              productName = element.products_color_waist.name + " (" + element.products_color_waist.color.name + " - " + element.products_color_waist.waist.name + ")"
            }       

            if(element.products_compound_id) {
              productCompoundID = element.products_compound.id
              productCompoundName = element.products_compound.name            
            }    

            if(element.unit_measurement_id) {
              unitMeasurementID = element.unit_measurement.id
              unitMeasurementName = element.unit_measurement.name            
            }                               

            this.arr.export.push({
              id: element.id,
              date: item.date,    
              voucherReference: voucherReference,          
              customersID: customersID,
              customersCode: customersCode,
              customersName: customersName,                   
              observations: item.observations,
              productID: productID,
              productCode: productCode,
              productName: productName,
              productCompoundID: productCompoundID,
              productCompoundName: productCompoundName,                                          
              quantity: element.quantity,
              quantityRemit: element.quantity_remit,
              quantityPending: parseFloat(element.quantity) - parseFloat(element.quantity_remit),
              unitMeasurementID: unitMeasurementID,
              unitMeasurementName: unitMeasurementName,  
              vto_days: element.vto.days,
              vto_status: element.vto.status,
            })            
          });
      },
      
      // FILTER
      filterLoad() {   
        this.filterLoadZone()          
        this.filterLoadCustomers()          
        this.filterLoadPointSale()                        
      },
      filterLoadZone() {
        var result = serviceAPI.obtenerZona()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.zone = []
          data.forEach(element => {            
            this.arr.filters.zone.push({ code: element.id, label: element.name })            
          });          
        })   
      },      
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {          
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },       
        
      // TREE
      loadCustomers() {
        if(this.parameters.remitMultipleItems) {
          this.loadCustomersMultipleItems()
        } else {
          this.loadCustomersGroupItems()
        }              
      },
      loadCustomersMultipleItems() {
        let loader = this.$loading.show()   
        
        var result = serviceAPI.filtrarPendienteRemitir(this.filters)
        
        result.then((response) => {
          loader.hide()

          this.arr.pending = [];
          this.customers = [];  
          this.customersTree = new Tree(this.customers);  

          var data = response.data
          if(data.length) {
            var arrZone = []
            data.forEach(element => {
              var zone_id = 0
              var zone_name = 'SIN ZONA'
              if(element.sale) {
                if(element.sale.customer.zone) {
                  zone_id = element.sale.customer.zone.zone.id
                  zone_name = element.sale.customer.zone.zone.name
                }
              }
              if(element.order) {
                if(element.order.customer.zone) {
                  zone_id = element.order.customer.zone.zone.id
                  zone_name = element.order.customer.zone.zone.name
                }
              }              

              if(element.sale) {
                arrZone.push({
                  id: zone_id,
                  name: zone_name,
                  customer: {
                    id: element.sale.customer.id,
                    name: element.sale.customer.name,
                  }
                })                
              }
              
              if(element.order) {
                arrZone.push({
                  id: zone_id,
                  name: zone_name,
                  customer: {
                    id: element.order.customer.id,
                    name: element.order.customer.name,
                  }
                })
              }

            });
              
            arrZone = Funciones.sortArrayInteger(arrZone,'id','asc')              
            
            var groupZone = []            
            arrZone.forEach(element => {
              if(groupZone[element.id] == undefined) {
                groupZone[element.id] = [] 
                groupZone[element.id].id = element.id
                groupZone[element.id].name = element.name
                groupZone[element.id].children = []
              }
            });            
            groupZone = groupZone.filter(Boolean)
            
            this.configTree(groupZone, arrZone)
          }           
        })
        .catch(error => {                
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });    
      },
      loadCustomersGroupItems() {
        let loader = this.$loading.show()   
        
        var result = serviceAPI.filtrarVoucherPendienteRemitir(this.filters)
        
        result.then((response) => {
          loader.hide()

          this.arr.pending = [];
          this.customers = [];  
          this.customersTree = new Tree(this.customers);  

          var data = response.data
          if(data.length) {
            var arrZone = []
            data.forEach(element => {
              var zone_id = 0
              var zone_name = 'SIN ZONA'
              if(element.customer.zone) {
                zone_id = element.customer.zone.zone.id
                zone_name = element.customer.zone.zone.name
              }

              arrZone.push({
                id: zone_id,
                name: zone_name,
                customer: {
                  id: element.customer.id,
                  name: element.customer.name,
                }
              })
            });
              
            arrZone = Funciones.sortArrayInteger(arrZone,'id','asc')              
            
            var groupZone = []            
            arrZone.forEach(element => {
              if(groupZone[element.id] == undefined) {
                groupZone[element.id] = [] 
                groupZone[element.id].id = element.id
                groupZone[element.id].name = element.name
                groupZone[element.id].children = []
              }
            });            
            groupZone = groupZone.filter(Boolean)
            
            this.configTree(groupZone, arrZone)
          }           
        })
        .catch(error => {         
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });    
      },

      configTree(group, data) { 
        this.customers = []             

        group.forEach((element, index) => {                                                          
          this.customers.push({            
            id: element.id,                  
            name: element.name,            
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,                     
            children: [],
          })

          this.getChildren(this.customers[index], data)
        })                  

        this.customersTree = new Tree(this.customers)
      },         
      getChildren(obj, data) {                                     
        if(data) {        
          data.forEach(element => {        
            if(obj.id == element.id) {

              var existe = false
              obj.children.forEach(item => {
                if(item.id == element.customer.id) {
                  existe = true
                }
              });

              if(!existe) {
                obj.children.push({
                  id: element.customer.id,      
                  name: element.customer.name,                
                  dragDisabled: true,
                  addTreeNodeDisabled: true,
                  addLeafNodeDisabled: true,
                  editNodeDisabled: true,
                  delNodeDisabled: true,                  
                  isLeaf: true,                            
                })    
              }                                  
            }  
          });     
        }   
      },      
      selectedItem(params) {     
        if(params.isLeaf) {
          this.obtenerPendienteRemitirByCliente(params.id)
        }        
      },

      // PENDING 
      obtenerPendienteRemitirByCliente(customer_id) {
        this.overlay.remits = true

        if(this.parameters.remitMultipleItems) {
          var result = serviceAPI.filtrarPendienteRemitir({'customers_id':customer_id})        
          result.then((response) => {
            var data = response.data
            this.overlay.remits = data.length > 0

            this.arr.pending = data             
            this.prepareExport(data)

            this.overlay.remits = false
          })                   
        } else {
          var result = serviceAPI.filtrarVoucherPendienteRemitir({'customers_id':customer_id})        
          result.then((response) => {
            var data = response.data  
            this.overlay.remits = data.length > 0

            this.arr.pending = data 
            this.prepareExport(data)

            this.overlay.remits = false
          })                   
        }                 
      },   
      getVariant(item) {
        var alert = ''
        if(this.parameters.remitFromSale) {
          alert = item.vto.alert
        }        
        return alert                
      }     
    }
  }
</script>